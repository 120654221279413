.AboutUsCards {
    display: flex;
    justify-content: space-between;
    padding-top: 4rem;
    padding-bottom: 14rem;
    @include mobile {
        flex-direction: column;
        padding-top: 4.4rem;
        padding-bottom: 5rem;
    }
    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 27.2rem;
        width: 37.6rem;
        padding: 4.8rem 4.4rem 2.8rem;
        @include mobile {
            flex-direction: row;
            padding: 3rem 2.6rem;
            width: 100%;
            min-height: 15rem;
        }

        + .AboutUsCards__item {
            @include mobile {
                margin-top: 1.4rem;
            }
        }

        svg {
            height: 8.7rem;

            @include mobile {
                width: 10.6rem;
                height: auto;
            }
        }

        .TextBlock {
            margin-top: 3rem;
            text-align: center;
            @include mobile {
                margin-top: 0;
                margin-left: 2.5rem;
                text-align: left;
            }
        }
    }
}
