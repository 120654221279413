.Footer {
    width: 100%;
    display: block;
    position: absolute;
    bottom: 0;
    white-space: nowrap;
    @include mobile {
        position: relative;
    }

    &__nav {
        display: flex;
        justify-content: flex-end;
        margin-top: 0.7rem;
        @include mobile {
            margin-top: 0;
            justify-content: flex-start;
        }

        ul {
            display: flex;
            @include mobile {
                display: flex;
                flex-direction: column-reverse;
                width: 100%;
                max-width: 31rem;
                // columns: 2;
                margin-bottom: -1rem;
            }
            li {
                @include mobile {
                    margin-bottom: 1rem;
                }
                &:not(:last-of-type) {
                    margin-right: 3rem;
                    @include mobile {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .Grid {
        @include mobile {
            flex-direction: column-reverse;
        }
    }
}
