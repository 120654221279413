p {
  margin: 0;
}

p + p {
  //margin-top: 25px;
}

small {
  font-size: 1.2rem;
  line-height: 1.2;
  font-weight: 300;
  color: inherit;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 500;
}
