.Container {
  display: block;
  padding-left: 14rem;
  padding-right: 14rem;
  margin: 0 auto;
  width: 100%;
  max-width: 144.4rem;

  @include mobile {
    padding-left: 1.8rem;
    padding-right: 1.8rem;
  }

  &--no-gutter {
    padding-left: 0;
    padding-right: 0;
  }
}
