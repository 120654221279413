.Modal {
  &__overlay {
    display: block;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(hsl(243, 13%, 72%), 0.2);
    overflow: auto;
    webkitoverflowscrolling: touch;
    z-index: 9999;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    pointer-events: none;
    @include mobile {
      padding: 1.8rem;
    }
  }

  &__container {
    display: block;
    padding: 5rem;
    max-width: 86.6rem;
    background-color: $white;
    box-shadow: $shadow-2;
    pointer-events: all;
    position: relative;
    @include mobile {
      width: 100%;
      padding: 3.1rem 1.8rem 3.7rem;
    }
  }

  &__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 4.2rem;
    height: 4.2rem;
    user-select: none;

    svg {
      width: 4.2rem;
      height: 4.2rem;
    }
  }
}
