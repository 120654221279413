.TextBlock {
    width: 100%;

    strong,
    b {
        font-weight: 600;
    }

    a,
    .Link {
        font-size: inherit;
        line-height: inherit;
    }

    p {
        color: inherit;
        font-size: inherit;
        + p {
            margin-top: 1em;
        }
    }

    &--l {
        font-size: 1.8rem;
        line-height: calc(32 / 18);
        letter-spacing: 0.05em;
        @include mobile {
            font-size: 1.4rem;
            line-height: calc(24 / 14);
        }
    }

    &--m {
        font-size: 1.5rem;
        line-height: calc(26 / 15);
        letter-spacing: 0.05em;
        @include mobile {
            font-size: 1.4rem;
        }
    }

    &--mb {
        @include mobile {
            font-size: 1.6rem;
            line-height: calc(24 / 15);
        }
    }
}
