.ContactForm {
  flex-wrap: nowrap;
  @include mobile {
    flex-direction: column;
  }
  &__left {
    display: flex;
    max-width: 78%;
    flex: 0 0 78%;
    @include mobile {
      flex-direction: column;
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
  &__right {
    max-width: 22%;
    flex: 0 0 22%;
    margin-left: 5.4rem;
    @include mobile {
      display: flex;
      flex-wrap: wrap;
      max-width: 100%;
      flex: 0 0 100%;
      margin-left: 0;
    }
    .TextBlock {
      @include mobile {
        width: 50%;
        &:first-child,
        &:nth-child(3) {
          order: -1;
        }
      }
    }
  }

  &__image {
    width: 43.8rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @include mobile {
      width: 100%;
      padding-bottom: 113%;
      order: 2;
    }
  }

  &__form {
    display: block;
    padding: 3.9rem 5rem;
    flex: 1;

    @include mobile {
      padding: 0;
    }
  }
}
