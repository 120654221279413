.BankLinks {
    &__container {
        display: flex;
        flex-wrap: wrap;
        margin: -1rem -0.8rem;
        @include mobile {
            margin: -0.6rem;
        }
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        margin: 1rem 0.9rem;
        width: 17.9rem;
        height: 9.8rem;
        background-color: $sand;
        position: relative;
        cursor: pointer;

        @include mobile {
            width: calc(33.33% - 1.2rem);
            margin: 0.6rem;
            height: 15vw;
            padding: 1.5rem 1rem;
        }
        img {
            max-width: 80%;
            max-height: 100%;
            display: block;
            margin: 0 auto;
            @include mobile {
                max-width: 100%;
            }
        }

        input {
            &:checked {
                ~ .BankLinks__checkmark {
                    border-color: $green;
                }
            }
        }
    }

    &__checkmark {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        border: 2px solid transparent;
        transition: border-color $trans;
        pointer-events: none;
    }
}
