.Section {
    position: relative;
    .Container {
        background-repeat: no-repeat;
    }
    &--home-cta {
        .Container {
            background-size: 88rem;
            background-position: calc(100% + 16rem) bottom;
            @include mobile {
                background-size: 64rem;
                background-position: calc(100% + 18.7rem) bottom;
            }
        }
    }

    &--home-hero {
        .Container {
            min-height: 50rem;
            background-size: 71.6rem;
            background-position: left bottom;

            @include mobile {
                background-image: none !important;
            }
        }
    }

    &--about-us {
        .Image {
            //width: calc(100% + 6.7rem);
            width: calc(100%);
            //position: absolute;
            // right: 6.7rem;
            position: relative;
            z-index: 2;
            top: 0;

            @include mobile {
                width: 100%;
                position: static;
            }
        }
    }

    &--text-page {
        .Container {
            > * {
                max-width: 96.4rem;
            }
        }
    }
}
