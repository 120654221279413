$spacing-shortcuts: (
  'margin': 'm',
  'padding': 'p',
);
$spacing-directions: (
  'top': 't',
  'right': 'r',
  'bottom': 'b',
  'left': 'l',
);

@each $property, $shortcut in $spacing-shortcuts {
  @for $i from 1 through 20 {
    // generator step
    $step: $i * 0.6;

    // All directions
    .#{$shortcut}-#{$i} {
      #{$property}: #{$step}rem;
    }

    // Horizontal axis
    .#{$shortcut}x-#{$i} {
      #{$property}-left: #{$step}rem;
      #{$property}-right: #{$step}rem;
    }

    // Vertical axis
    .#{$shortcut}y-#{$i} {
      #{$property}-top: #{$step}rem;
      #{$property}-bottom: #{$step}rem;
    }

    // Relative directions
    @each $direction, $direction-shortcut in $spacing-directions {
      .#{$shortcut}#{$direction-shortcut}-#{$i} {
        #{$property}-#{$direction}: #{$step}rem;
      }
    }
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

@include mobile {
  @each $property, $shortcut in $spacing-shortcuts {
    @for $i from 0 through 20 {
      // generator step
      $step: $i * 0.3;

      // All directions
      .m\:#{$shortcut}-#{$i} {
        #{$property}: #{$step}rem;
      }

      @if $shortcut == 'm' {
        .m\:-#{$shortcut}-#{$i} {
          #{$property}: #{$step * -1}rem;
        }
      }

      // Horizontal axis
      .m\:#{$shortcut}x-#{$i} {
        #{$property}-left: #{$step}rem;
        #{$property}-right: #{$step}rem;
      }

      @if $shortcut == 'm' {
        .m\:-#{$shortcut}x-#{$i} {
          #{$property}-left: #{$step * -1}rem;
          #{$property}-right: #{$step * -1}rem;
        }
      }

      // Vertical axis
      .m\:#{$shortcut}y-#{$i} {
        #{$property}-top: #{$step}rem;
        #{$property}-bottom: #{$step}rem;
      }

      @if $shortcut == 'm' {
        .m\:-#{$shortcut}y-#{$i} {
          #{$property}-top: #{$step * -1}rem;
          #{$property}-bottom: #{$step * -1}rem;
        }
      }

      // Relative directions
      @each $direction, $direction-shortcut in $spacing-directions {
        .m\:#{$shortcut}#{$direction-shortcut}-#{$i} {
          #{$property}-#{$direction}: #{$step}rem;
        }

        @if $shortcut == 'm' {
          .m\:-#{$shortcut}#{$direction-shortcut}-#{$i} {
            #{$property}-#{$direction}: #{$step * -1}rem;
          }
        }
      }
    }
  }

  .m\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .m\:ml-auto {
    margin-left: auto;
  }

  .m\:mr-auto {
    margin-right: auto;
  }
}
