.SideNav {
  @include mobile {
    display: flex;
    flex-wrap: wrap;
  }
  li {
    &:not(:last-of-type) {
      margin-bottom: 0.8rem;
      @include mobile {
        margin-right: 1.7rem;
        margin-bottom: 0;
      }
    }
  }

  .Link {
    align-items: baseline;
    font-weight: 600;
    @include mobile {
      font-size: 1.2rem;
    }
    span {
      display: inline-block;
      width: 3.2rem;
      font-size: 2.4rem;
      font-weight: bold;
      letter-spacing: 0.05em;

      @include mobile {
        font-size: 1.2rem;
        width: auto;
        padding-right: 0.5em;
      }
    }
  }
}
