.Search {
  width: 47.4rem;
  position: relative;
  @include mobile {
    width: 100%;
  }

  .Form__input {
    border-color: transparent;
    padding-right: 20rem;
    box-shadow: $shadow-3;
    @include mobile {
      padding-right: 3.2rem;
    }
  }

  .Form__control--has-tooltip {
    .Form__input {
      @include mobile {
        padding-right: 5rem;
      }
    }
  }

  .Form__label {
    max-width: calc(100% - 20rem);

    @include mobile {
      max-width: unset;
    }
  }

  &__button {
    position: absolute;
    z-index: 2;
    top: 0.4rem;
    right: 0.4rem;
    width: 18rem;

    @include mobile {
      position: static;
      width: 100%;
      margin-top: 1.3rem;
    }
  }
}
