.Grid {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  &--has-gutter {
    margin-left: -1rem;
    margin-right: -1rem;
    @include mobile {
      margin-left: 0;
      margin-right: 0;
    }
    .Grid_col {
      padding-left: 1rem;
      padding-right: 1rem;
      @include mobile {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &__col {
    max-width: 100%;
    flex: 0 0 100%;

    &--25\% {
      max-width: 25%;
      flex: 0 0 25%;
      @include mobile {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }

    &--50\% {
      max-width: 50%;
      flex: 0 0 50%;
      @include mobile {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }

    &--75\% {
      max-width: 75%;
      flex: 0 0 75%;
      @include mobile {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }
}
