.DeveloperNav {
  display: block;
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  background-color: #666;
  max-width: 30rem;

  @include mobile {
    top: unset;
    bottom: 0;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    width: 5rem;
    height: 5rem;
    background-color: #000;
  }
  ul {
    padding: 3rem;
  }
}
