$trans: 250ms ease-in-out;

$font: 'Manrope', sans-serif;

$white: #ffffff;
$black: #282425;
$violet: #7b65d6;
$violet-light: #efeff9;
$red: #f69576;
$green: #00baac;
$sand: #f8f8f8;
$grey: #b5b5b5;
$grey-light: #cecece;

$text-grey-dark: #636363;
$text-black: $black;

$hover-bg-green: lighten($green, 3%);
$hover-bg-red: lighten($red, 5%);

$shadow-color: #89678a;
$shadow-2: 1rem 1rem 2rem rgba($shadow-color, 0.16);
$shadow-3: 1rem 1rem 3rem rgba($shadow-color, 0.16);

$radius: 0.4rem;
