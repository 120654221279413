$colors: (
  'violet': $violet,
  'violet-light': $violet-light,
  'black': $black,
  'white': $white,
  'green': $green,
  'grey-dark': $text-grey-dark,
  'red': $red,
  'transparent': transparent,
);

@each $color, $value in $colors {
  .text-#{$color} {
    color: $value;
  }

  .bg-#{$color} {
    background-color: $value;
  }
  .border-#{$color} {
    border-color: $value;
  }
}

.bg-sand-light {
  background-color: $sand;
}

@include mobile {
  @each $color, $value in $colors {
    .m\:text-#{$color} {
      color: $value;
    }

    .m\:bg-#{$color} {
      background-color: $value;
    }
    .m\:border-#{$color} {
      border-color: $value;
    }
  }

  .m\:bg-sand-light {
    background-color: #f1f1f2;
  }
}
