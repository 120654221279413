.Message {
  padding: 3rem 3.9rem 3rem 3.4rem;
  @include mobile {
    padding: 2.1rem 1.7rem 1.7rem;
  }

  &--violet {
    background-color: $violet-light;
    .TextBlock {
      color: $violet;
    }
  }

  &--red {
    background-color: #fbe2da;
    .TextBlock {
      color: #f15a29;
    }
  }
}
