.font-semi-bold {
  font-weight: 600;
}
.font-medium {
  font-weight: 500;
}

.font-light {
  font-weight: 300;
}

.line-h-2 {
  line-height: 2;
}

.relative {
  position: relative;
}

.opacity-50 {
  opacity: 0.5;
}

.z-2 {
  z-index: 2;
}

.shadow-2 {
  box-shadow: $shadow-2;
}

.shadow-3 {
  box-shadow: $shadow-3;
}

.w-full {
  width: 100%;
}

.block {
  display: block;
}

.hidden {
  display: none;
}

.inline-flex {
  display: inline-flex;
  width: auto;
}
.inline-block {
  display: inline-block;
  width: auto;
}
.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.w-full {
  width: 100%;
}

.text-center {
  text-align: center;
}

.whitespace-nowrap {
  white-space: nowrap;
}

@include mobile {
  .m\:text-center {
    text-align: center;
  }

  .m\:block {
    display: block;
  }

  .m\:flex {
    display: flex;
  }

  .m\:hidden {
    display: none;
  }

  .m\:flex-col {
    flex-direction: column;
  }

  .m\:justify-start {
    justify-content: flex-start;
  }

  .m\:shadow-none {
    box-shadow: none;
  }

  .m\:w-auto {
    max-width: unset;
    flex: auto;
    width: auto;
  }
}
