.Faq {
  &__item {
    width: 100%;
    box-shadow: $shadow-3;

    &:not(:last-of-type) {
      margin-bottom: 1.2rem;
    }
  }

  &__content {
    padding: 1rem 3.1rem 1.5rem 3.5rem;
  }
  &__question {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 2.3rem 2.1rem 1.5rem 3.5rem;
    line-height: calc(28 / 16);
    user-select: none;
    cursor: pointer;

    h3 {
      font-weight: 500;
      font-size: 1.6rem;
      transition: color $trans;
    }
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.4rem;
    height: 1.4rem;
    position: relative;
    margin-top: 0.3rem;
    margin-left: 6rem;
    flex-shrink: 0;

    &::before,
    &::after {
      content: '';
      display: block;
      background-color: $green;
      position: absolute;
    }
    &::before {
      width: 2px;
      height: 100%;
      transition: transform $trans;
    }
    &::after {
      width: 100%;
      height: 2px;
    }

    &.isActive {
      &::before {
        transform: rotate(90deg);
      }
    }
  }
}

.slide-enter {
  max-height: 0;
  overflow: hidden;
}
.slide-enter-active {
  max-height: 999px;
  transition: max-height 500ms ease-in-out;
}
.slide-exit {
  max-height: 999px;
}
.slide-exit-active {
  max-height: 0;
  overflow: hidden;
  transition: max-height 500ms ease-in-out;
}
