.Link {
  display: inline-flex;
  align-items: center;
  font-size: 1.4rem;
  letter-spacing: 0.05em;
  transition: color $trans;
  user-select: none;
  cursor: pointer;

  &--has-underline {
    text-decoration: underline;
  }

  svg {
    fill: currentColor;
    transition: fill $trans;
  }

  &.text-black,
  &.text-grey-dark {
    &:hover {
      color: $violet;
    }

    &.isActive {
      transition: none;
      color: $violet;
    }
  }

  &.text-white {
    &:hover {
      color: $green;
    }

    &.isActive {
      transition: none;
      color: $green;
    }
  }
  &.text-green {
    &:hover {
      color: lighten($green, 6);
    }

    &.isActive {
      transition: none;
      color: lighten($green, 6);
    }
  }
}
