.CookieConsent {
    width: 100%;
    max-width: 43.4rem;
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    z-index: 999;

    * {
        line-height: 1.3;
    }
    .small {
        font-size: 80%;
    }
    button {
        margin-right: 18px;
        + button {
            margin: 0;
        }
    }

    .fl {
        display: flex;
        align-items: center;
    }

    @include mobile {
        right: 1.8rem;
        bottom: 1.8rem;
        left: 1.8rem;
        max-width: unset;
        width: auto;
    }

    &__content {
        // padding: 4.9rem 4.7rem 4rem;
        padding: 2.9rem 2.7rem 2rem;
        @include mobile {
            // padding: 3.5rem 2.5rem;
            padding: 1.5rem 1.5rem;
        }
    }

    .Button {
        flex-shrink: 1;
        width: 18rem;
        padding: 0;
        min-width: 0;
        // @include mobile {
        //     width: auto;
        // }
    }

    input {
        border-color: #fff;
    }

    .text-grey-dark {
        color: #fff;
    }

    .TextBlock {
        font-size: 1.6rem;
    }
}
