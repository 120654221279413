.Main {
  min-height: calc(100vh - 10.1rem);
  &::before {
    content: '';
    display: block;
    width: 57rem;
    height: 49.2rem;
    background-image: url('../../assets/footer-docoration-2.svg');
    background-size: 105.8rem;
    background-position: 100% top;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    left: 50vw;
    transform: translateX(calc(-144rem / 2));
    z-index: 0;

    @include mobile {
      background-image: url('../../assets/footer-decoration-mobile.svg') !important;
      height: 36.6rem !important;
      width: 100%;
      left: 0;
      transform: translateX(0);
      background-position-x: 86%;
    }
  }

  &--home {
    &::before {
      background-image: url('../../assets/footer-decoration.svg');
    }
  }

  &--declare,
  &--declare\/success,
  &--privacy-policy {
    &::before {
      height: 24.5rem;
    }
  }

  &--declare\/2 {
    &::before {
      height: 40.5rem;
    }
  }
  &--about {
    &::before {
      height: 36.4rem;
    }
  }

  &--price {
    &::before {
      height: 25rem;
    }
  }
}
