.Header {
    @include mobile {
        position: fixed !important;
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;
        // box-shadow: $shadow-2;
    }
    &__logo {
        width: 24rem;
        height: 5rem;

        @include mobile {
            height: 3.8rem;
            width: 18.3rem;
        }
    }

    &__burger {
        display: none;
        @include mobile {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 5.8rem;
            width: 5.8rem;
            position: absolute;
            right: 0.4rem;
            top: 0;
            z-index: 2;
            svg {
                width: 2.5rem;
                height: 1.7rem;
            }
        }
    }

    &__container {
        padding: 2.8rem 2.4rem 2.3rem;

        @include mobile {
            padding: 1rem 1.8rem;
        }
    }

    &__grid {
        display: flex;
        flex-wrap: nowrap;
    }

    &__nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @include mobile {
            display: none;
            flex-direction: column;
            align-items: flex-end;
            padding: 9.4rem 2rem 4.8rem;
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            background-color: $violet;
            text-align: right;
        }

        &.isActive {
            @include mobile {
                display: flex;
            }
        }
    }

    &__nav-primary {
        display: flex;
        padding: 0 6rem 0 10rem;

        @include mobile {
            flex-direction: column;
            padding: 0;
        }

        li {
            &:not(:last-of-type) {
                margin-right: 2.5rem;
                @include mobile {
                    margin: 0 0 2.4rem 0;
                }
            }
        }

        .Link {
            @include mobile {
                color: $white !important;
                font-size: 1.6rem;
                font-weight: 600;
                letter-spacing: 0.025em;
            }
        }
    }

    &__nav-secondary {
        display: flex;
        @include mobile {
            flex-direction: column;
            margin-top: 5.5rem;
        }
        li {
            display: inline-flex;
            align-self: center;
            @include mobile {
                align-self: flex-end;
            }
            &:not(:last-of-type) {
                margin-right: 6rem;
                @include mobile {
                    text-align: right;
                    margin: 0 0 2.4rem 0;
                }
            }
        }

        .Link {
            @include mobile {
                color: $white !important;
                font-size: 1.4rem;
                font-weight: 300;

                & > span {
                    font-weight: 300;
                }

                svg {
                    fill: $white;
                }
            }
        }
    }

    &__lang {
        position: relative;
        user-select: none;

        &:hover {
            .Header__lang-content {
                display: block;
            }
            .Header__lang-tile svg {
                transform: rotate(180deg);
            }
        }
    }

    &__lang-tile {
        display: inline-flex;
        align-items: center;

        button {
            padding-right: 1.9rem;
        }
        svg {
            padding-top: 0.2rem;
            position: absolute;
            right: 0;
            pointer-events: none;
        }
    }

    &__lang-content {
        position: absolute;
        padding-top: 0.5rem;
        display: none;

        .link {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
    }
}
