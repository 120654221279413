@mixin selection {
  &::-webkit-selection {
    @content;
  }
  &::-moz-selection {
    @content;
  }
  &::selection {
    @content;
  }
}

//  MEDIA queries
@mixin mobile {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 1440px) {
    @content;
  }
}

@mixin laptop {
  @media only screen and (max-width: 1439px) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (max-width: 1000px) {
    @content;
  }
}
