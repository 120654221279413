.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 4.8rem;
  cursor: pointer;
  user-select: none;
  font-family: $font;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  line-height: calc(21 / 15);
  white-space: nowrap;
  flex-shrink: 0;
  border-radius: 5rem;

  @include mobile {
    width: 100%;
  }

  &--primary {
    min-width: 16rem;
    padding: 0 4.2rem;
    transition: background-color $trans;

    &:hover {
      &.bg-green {
        background-color: $hover-bg-green;
      }
      &.bg-red {
        background-color: $hover-bg-red;
      }
    }
  }

  &--secondary {
    min-width: 16rem;
    padding: 0 4.2rem;
    border-width: 1px;
    border-style: solid;
    transition: background-color $trans, color $trans;
    @include mobile {
      text-transform: none;
    }

    &:hover {
      &.border-green {
        background-color: $green;
        color: $white;
      }
      &.border-red {
        background-color: $red;
        color: $white;
      }
    }
  }
}
