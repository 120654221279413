.Tabs {
    display: block;

    &__overflow-container {
        @include mobile {
            padding-left: 1.8rem;
            padding-right: 1.8rem;
            width: fit-content;
        }
    }

    &__content:empty {
        display: none;
    }

    &__head {
        @include mobile {
            margin-left: -1.8rem;
            margin-right: -1.8rem;
            width: calc(100% + 3.6rem);
            overflow-y: auto;
            white-space: nowrap;
            position: relative;
            &::after {
                content: '';
                display: block;
                width: calc(100% - 3.6rem);
                position: absolute;
                left: 1.8rem;
                bottom: 0;
                border-bottom: 1px solid $grey-light;
            }
        }
        ul {
            display: flex;
            border-bottom: 1px solid $grey-light;

            li {
                + li {
                    margin-left: 4rem;
                }
                .Link {
                    @include mobile {
                        @include mobile {
                            font-size: 1.3rem;
                        }
                    }
                    &:hover {
                        color: $black !important;
                    }
                }

                &.isActive {
                    position: relative;
                    &::before {
                        content: '';
                        width: 100%;
                        height: 1px;
                        background-color: $black;
                        position: absolute;
                        bottom: -1px;
                        left: 0;
                        z-index: 2;
                    }

                    .Link {
                        color: $black;

                        &:hover {
                            color: $black !important;
                        }
                    }
                }
            }
        }

        .Link {
            padding: 1.2rem 0;
        }
    }
}
