.Table {
    &__overflow {
        @include mobile {
            overflow-x: auto;
            margin-right: -1.8rem;
            margin-left: -1.8rem;
        }
    }

    &__container {
        //width: fit-content;
        @include mobile {
            padding: 0 1.8rem;
        }
    }

    thead {
        border-bottom: 1px solid $grey;
        td {
            padding-top: 1.2rem;
            padding-bottom: 1.2rem;
        }
    }

    tbody {
        tr {
            &:last-of-type {
                border-top: 1px solid $grey;
                td {
                    padding-top: 1.2rem;
                    padding-bottom: 1.2rem;
                }
                p {
                    font-size: 1.3rem;
                    font-weight: 500;
                    line-height: calc(16 / 14);
                    color: $text-black;
                    @include mobile {
                        font-size: 1.4rem;
                    }
                }
            }
            &:first-of-type {
                td {
                    padding-top: 1.2rem;
                }
            }
        }

        td {
        }
    }

    td {
        padding-left: 1.3rem;
        padding-right: 1.3rem;
        vertical-align: top;

        &:first-of-type {
            padding-left: 0;
        }
        &:last-of-type {
            padding-right: 0;
        }
    }

    h3,
    h4 {
        font-size: 1.3rem;
        font-weight: 500;
        letter-spacing: 0.05em;
        line-height: calc(16 / 13);
        color: $text-black;
    }

    p {
        font-size: 1.4rem;
        font-weight: 300;
        letter-spacing: 0.05em;
        line-height: calc(26 / 14);
        color: $text-grey-dark;
    }

    &--regular {
        thead {
            border-bottom: none;
            td {
                padding-top: 1.4rem;
                padding-bottom: 1.4rem;
                @include mobile {
                    padding-top: 1.6rem;
                    padding-bottom: 1.6rem;
                }
            }
        }
        tbody {
            td {
                padding-top: 1.4rem;
                padding-bottom: 1.4rem;
                @include mobile {
                    padding-top: 1.6rem;
                    padding-bottom: 1.6rem;
                }
            }
            tr {
                border-top: 1px solid $grey;
                &:last-of-type {
                    border-top: 1px solid $grey;
                    td {
                        padding-top: 1.4rem;
                        padding-bottom: 1.4rem;
                    }
                    p {
                        font-size: 1.5rem;
                        font-weight: 300;
                        line-height: calc(16 / 14);
                        color: $text-grey-dark;
                    }
                }
                &:first-of-type {
                    td {
                        padding-top: 1.4rem;
                    }
                }
            }

            td {
            }
        }

        h3,
        h4 {
            font-size: 1.5rem;
        }

        p {
            font-size: 1.5rem;
            font-weight: 300;
        }
    }

    strong {
        font-weight: 500;
        color: $black;
    }

    @include mobile {
        tbody {
            td {
                padding-top: 0.6em !important;
                padding-bottom: 0.6em !important;
                line-height: 1.3;
                p {
                    line-height: 1.2;
                }
            }
            tr:first-child td {
                padding-top: 1.2em !important;
            }
            tr:not(:last-child):not(:first-child) td {
                padding-bottom: 1.2em !important;
            }
        }
    }
}
