.HowTo {
  display: flex;
  @include mobile {
    flex-direction: column;
  }
  &__item {
    width: 22rem;
    padding: 3.7rem 3rem;
    background-color: $white;
    box-shadow: $shadow-2;

    @include mobile {
      display: flex;
      width: 100%;
      box-shadow: none;
      padding: 0;
    }

    &:not(:last-of-type) {
      margin-right: 1.6rem;
      @include mobile {
        margin-right: 0;
        margin-bottom: 3rem;
      }
    }
  }
  &__title {
    font-size: 1.4rem;
    line-height: 2.8rem;
    letter-spacing: 0.05em;
    color: $text-grey-dark;
    span {
      display: block;
      font-size: 3.6rem;
      font-weight: bold;
      color: $violet;
      margin-bottom: 0.4rem;

      @include mobile {
        display: inline;
        font-size: 1.4rem;
        margin-bottom: 0;
        padding-right: 0.4em;
      }
    }
  }

  .Image {
    width: 9.3rem;
    height: 9.3rem;
    margin-bottom: 2.3rem;
    flex-shrink: 0;

    @include mobile {
      width: 4.8rem;
      height: 4.8rem;
      height: auto;
      margin-bottom: 0;
      margin-right: 4.8rem;
    }
  }
}
