.Form {
    display: block;
    width: 100%;

    &__control {
        display: block;
        width: 100%;
        position: relative;

        + .form__control {
            margin-top: 1.6rem;
        }

        &.isActive {
            .Form__label {
                opacity: 0;
            }
        }

        &.hasError {
            .Form__input,
            .Form__textarea,
            .Form__select {
                border-color: #f15a29;
            }
        }

        &--has-tooltip {
            .Form__input {
                padding-right: 4rem;
            }
        }
    }

    &__label {
        display: inline-flex;
        align-items: center;
        font-size: 1.5rem;
        font-weight: 300;
        letter-spacing: 0.05rem;
        line-height: calc(21 / 15);
        position: absolute;
        left: 0;
        transition: opacity $trans;
        pointer-events: none;
        z-index: 2;
        @include mobile {
            font-size: 1.4rem;
        }
        a {
            font-size: inherit;
        }
    }

    &__textarea,
    &__input {
        width: 100%;
        border: 1px solid $grey;
        font-family: $font;
        font-weight: 400;
        transition: border $trans;

        &:focus {
            border: 1px solid $green;
        }
    }

    &__input {
        &--l {
            height: 5.6rem;
            padding: 0 3.4rem;
            border-radius: 5rem;
            font-size: 1.8rem;
            line-height: 1.2;

            + label {
                padding: 0 3.4rem;
                top: 1.7rem;
            }

            @include mobile {
                height: 4.8rem;
                padding: 0 3.2rem;
                font-size: 1.5rem;

                + label {
                    padding: 0 3.2rem;
                    top: 1.4rem;
                }
            }
        }

        &--m {
            height: 4.8rem;
            padding: 0 3.2rem;
            border-radius: 5rem;
            font-size: 1.5rem;
            line-height: 1.2;

            + label {
                padding: 0 3.2rem;
                top: 1.4rem;
            }
        }
    }

    &__select {
        .Select {
            &__control {
                height: 4.8rem;
                padding: 0 3.2rem;
                border-radius: 5rem;
                box-shadow: none;
                z-index: 2;
                background-color: transparent;

                &--is-focused {
                    border-color: $green !important;
                }

                &--menu-is-open {
                    border: 2.5rem;
                }
            }

            &__placeholder {
                font-size: 1.5rem;
                font-weight: 300;
                letter-spacing: 0.05rem;
                line-height: calc(21 / 15);
                color: $text-grey-dark;
            }

            &__indicators {
                transform: translateX(2.5rem);
            }

            &__indicator-separator {
                display: none;
            }

            &__value-container {
                padding: 0 !important;
            }

            &__single-value {
                font-size: 1.5rem;
                line-height: 1.2;
                color: $text-black;
            }

            &__menu {
                margin-top: -4.8rem;
                padding-top: 5.8rem;
                padding-right: 1.8rem;
                border-top: none;
                border-radius: 2.5rem;
                border: 1px solid $green;
                box-shadow: none;
            }

            &__menu-list {
                max-height: 22rem;
            }
            &__option {
                padding-left: 3.2rem;
                font-size: 1.5rem;
                line-height: 1.2;
                color: $text-grey-dark;
                &--is-focused,
                &--is-selected {
                    background-color: transparent;
                    color: $green;
                }
            }
            &__input {
                font-size: 1.5rem;
                font-size: 400;
            }
        }
    }

    &__checkbox {
        width: 2.1rem;
        height: 2.1rem;
        border: 1px solid $grey;
        flex-shrink: 0;
        background-repeat: no-repeat;
        background-size: 1.33rem;
        background-position: center;
        user-select: none;
        cursor: pointer;
        + .Form__label {
            user-select: none;
            position: relative;
            padding-left: 1.4rem;
            pointer-events: all;
            cursor: pointer;
            display: inline;
            @include mobile {
                margin: 0.75rem 0;
            }
        }

        &:checked {
            background-image: url('../../assets//icons/checked-green.svg');
        }
        @include mobile {
            margin: 0.75rem 0;
        }
    }

    &__textarea {
        resize: none;
        padding: 1.8rem 3.2rem;
        min-height: 10.8rem;
        border-radius: 1.2rem;
        font-size: 1.5rem;
        line-height: 1.2;

        + label {
            padding: 1.8rem 3.2rem;
        }
    }

    &__tooltip {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        width: 4rem;

        &--l {
            height: 5.6rem;
            @include mobile {
                height: 4.8rem;
            }
        }
        &--m {
            height: 4.8rem;
        }
    }

    // &__error-message {
    //   margin-top: 0.3rem;
    //   font-size: 1.4rem;
    //   line-height: 1.2;
    //   font-weight: 300;
    //   color: $red;
    //   display: none;
    //   user-select: none;
    // }
}
