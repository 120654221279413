.Heading {
  font-weight: bold;
  color: inherit;

  &--l {
    font-size: 4.4rem;
    line-height: calc(60 / 44);
    @include mobile {
      font-size: 3rem;
    }
  }

  &--m {
    font-size: 4rem;
    line-height: calc(56 / 40);
    @include mobile {
      font-size: 2.6rem;
    }
  }

  &--s {
    font-size: 2.8rem;
    line-height: calc(40 / 28);
    @include mobile {
      font-size: 2rem;
    }
  }

  &--xs {
    font-size: 2.2rem;
    line-height: calc(40 / 22);
    @include mobile {
      font-size: 2rem;
    }
  }
  &--xxs {
    font-size: 1.6rem;
    line-height: 1.5;
    @include mobile {
      font-size: 1.8rem;
    }
  }
}
