@charset "utf-8";

// Import global base styles and resets
@import 'base/all';

//  Import UI lib components styles
@import 'ui/all';

//  Import component styles
@import 'components/all';

//  Import utilities and helper classes
@import 'utils/all';

.bottomLink {
    user-select: none;
    color: #00baac;
    margin: 1em 1em 0 0;
    font-size: 1.4rem;
    display: inline-block;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.Message {
    ul {
        margin: initial;
        padding: 0 0 0 1.5em;
    }
    li {
        list-style: initial;
    }
}

.fileName {
    opacity: 1 !important;
    color: #282425;
    font-weight: 500;
}

.step1Actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mobile {
        display: block;
    }
}

input[type='file'] {
    text-indent: -99999px;
    -webkit-appearance: none;
    -o-appearance: none;
    appearance: none;
    color: transparent;
    font-size: 0;
    vertical-align: text-bottom;
}

table {
    width: 100%;
}

.SideNav li:last-child {
    pointer-events: none;
}

.sent {
    min-height: 55.3rem;
}

@media only screen and (max-width: 767px) {
    .sent {
        min-height: 0;
    }
}

.btnGroup {
    > * {
        margin-right: 20px;
    }
    @include mobile {
        > *:not(:last-child) {
            margin-bottom: 20px;
        }
    }
}

.loader {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(175, 174, 193, 0.2);
    z-index: 1000;
    top: 0;
    left: 0;
}

.Message a {
    text-decoration: underline;
}

.fileIcon {
    position: absolute;
    width: 2.5em;
    height: 2.5em;
    right: 0;
    top: 50%;
    padding: 0.2em;
    right: 1.25em;
    margin-top: -1.25em;
}

.Header {
    z-index: 10;
    position: relative;
}

.SideNavDisabled {
    pointer-events: none;
}

.headerInfo {
    cursor: pointer;
    top: 0.05em;
    position: relative;
    margin: 0 0 0 0.5em;
}

.Modal__container_big {
    max-width: 160rem;
}

.Modal__container_stripe {
    width: 100%;
    max-width: 108.6rem;
    align-self: flex-start;
    margin-top: 16rem;
    @media only screen and (max-width: 767px) {
        margin-top: 0;
        padding-top: 4rem;
    }
}

.subrich {
    h2 {
        margin: 3rem 0 !important;
    }
}

.small-text {
    font-size: 1em;
    margin-bottom: 2em;
}

.rich {
    h2 {
        font-size: 2.2rem;
        line-height: 1.3;
        font-weight: bold;
        margin: 3rem 0;
        color: #282425;
    }
    h3 {
        font-size: 1.6rem;
        line-height: 1.5;
        font-weight: bold;
        margin: 1.8rem 0;
        color: #282425;
    }
    ul {
        margin: 1.8rem 0;
        padding: 0 0 0 1.5em;
    }
    li {
        list-style: initial;
    }
    table {
        margin: 1.8rem 0;
        border-collapse: collapse;
        border: 1px solid #b9b9b9;
        font-size: 90%;
        @media only screen and (max-width: 767px) {
            min-width: 900px;
        }
    }
    .table-wrap {
        @media only screen and (max-width: 767px) {
            overflow: auto;
            padding: 0 1.8rem;
            margin: 0 -1.8rem;
        }
    }
    table td {
        border: 1px solid #b9b9b9;
        padding: 0.25em 0.5em;
    }
    table td:first-child {
        border-left: 0px solid #b9b9b9;
    }
    table th {
        border: 2px solid #b9b9b9;
        padding: 5px;
    }
    a {
        text-decoration: underline;
        color: #282425;
        &:hover {
            text-decoration: none;
        }
    }
    [start] {
        margin: 0;
        padding: 0;
        li {
            font-size: 2.2rem;
            line-height: 1.3;
            font-weight: bold;
            margin: 3rem 0;
            color: #282425;
            list-style: none;
        }
    }
}

.SearchCaptcha {
    margin-top: 3em;
    display: flex;
}

.Section--home-cta {
    .SearchCaptcha {
        justify-content: flex-end;
    }
}

@media only screen and (max-width: 767px) {
    .SearchCaptcha {
        justify-content: center;
    }
    .Section--home-cta {
        .SearchCaptcha {
            justify-content: center;
        }
    }
    .bottomLinks {
        display: flex;
        margin-top: 10px;
        flex-direction: column;
    }
    .small-minimal-heading {
        h1 {
            margin: 0;
        }
    }
}

.BankLinks__info {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0.75em;
    font-size: 1.1em;
    text-align: center;
}

.waiting_for_the_payment {
    @media only screen and (max-width: 767px) {
        flex-direction: column;
    }
}

.wait-sheet {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999999;
    background: #fff;
    pointer-events: none;
    transition: opacity 300ms ease-in-out;
}

.success-loaded {
    .wait-sheet {
        opacity: 0;
    }
}

.Header__logo {
    @media only screen and (max-width: 767px) {
        width: 13.3rem;
        margin-left: -0.5em;
    }
}

.extraLangs {
    display: none;
    a {
        color: #636363;
        font-size: 1.3rem;
    }
    @media only screen and (max-width: 767px) {
        display: flex;
        
        align-items: center;
        margin: 0 4.3em 0 auto;
    }
}

.activeExtraLang {
    color: #00c9ba !important;
}